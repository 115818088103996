import { Term } from '@/model/Spf';

export type IpInfo = {
  query: string;
  countryCode: string;
  country: string;
  regionName: string;
  city: string;
  lat: number;
  lon: number;
  org: string;
  as: string;
  asname: string;
  asdomain: string;
  isAnycast: boolean;
}

export type RrBase = {
  raw: string;
  name: string;
  ttl: number;
  recordType: string;
}

export type A = RrBase & { ipv4: string; recordType: 'A'; }
export type AAAA = RrBase & { ipv6: string; recordType: 'AAAA'; }
export type CAA = RrBase & { flags: number, tag: string, value: string; recordType: 'CAA'; }
export type CNAME = RrBase & { target: string; recordType: 'CNAME'; }
export type MX = RrBase & { priority: number; target: string; recordType: 'MX'; }
export type NS = RrBase & { target: string; recordType: 'NS'; }
export type PTR = RrBase & { target: string; recordType: 'PTR'; }
export type SOA = RrBase & {
  host: string;
  admin: string;
  serial: number;
  refresh: number;
  retry: number;
  expire: number;
  minimum: number;
  recordType: 'SOA';
}
export type SRV = RrBase & {
  priority: number;
  weight: number;
  port: number;
  target: string;
  recordType: 'SRV';
}
export type TLSA = RrBase & {
  certificateUsage: number;
  selector: number;
  matchingType: number;
  certificateAssociationData: string;
  recordType: 'TLSA';
}
export type TXT = RrBase & { strings: string[]; recordType: 'TXT'; }
export type NotImplemented = RrBase & { recordType: string; }

export type RR = A | AAAA | CAA | CNAME | MX | NS | PTR | SOA | SRV | TLSA | TXT | NotImplemented

export type RecordInfo = {
  cnameChain: Array<string>;
  ipInfo: IpInfo | null;
  parsedSpf: Array<Term> | null;
}

export type RecordWithInfo = {
  record: RR;
} & RecordInfo

export type Geolocation = {
  lat: number;
  lon: number;
}

export type DnsServer = {
  ip: string | null;
  domain: string | null;
  location: Geolocation | null;
}

export const emptyDnsServer: DnsServer = {
  ip: null,
  domain: null,
  location: null,
};

export type Query = {
  server: DnsServer,
  domain: string,
  recordType: string
}

export const emptyQuery: Query = {
  server: emptyDnsServer,
  domain: '',
  recordType: '',
};

export type DnsResponse = {
  answer: Array<RecordWithInfo>;
  authority: Array<RecordWithInfo>;
  additional: Array<RecordWithInfo>;
  // header: Header;
}

export const emptyResponse: DnsResponse = {
  answer: [],
  authority: [],
  additional: [],
};

export type QueryResult = {
  query: Query;
  response: DnsResponse;
}

export const emptyQueryResult: QueryResult = {
  query: emptyQuery,
  response: emptyResponse,
};

export type PopularRecords = {
  a: QueryResult;
  aaaa: QueryResult;
  cname: QueryResult;
  txt: QueryResult;
  ns: QueryResult;
  mx: QueryResult;
  soa: QueryResult;
}

export const emptyPopularRecords: PopularRecords = {
  a: emptyQueryResult,
  aaaa: emptyQueryResult,
  cname: emptyQueryResult,
  txt: emptyQueryResult,
  ns: emptyQueryResult,
  mx: emptyQueryResult,
  soa: emptyQueryResult,
};

export type GetRecordsResponse = {
  punycodeDomain: string;
  unicodeDomain: string;
  records: PopularRecords;
}

export type OtherRecordsResponse = {
  punycodeDomain: string;
  unicodeDomain: string;
  queryResult: QueryResult;
}

export type WebserversResponse = {
  punycodeDomain: string;
  unicodeDomain: string;
  a: QueryResult;
  aaaa: QueryResult;
}
