export function withoutTrailingDot(domainName: string) {
  return (domainName.endsWith('.') ? domainName.slice(0, -1) : domainName);
}

export function domainNameToUrlPath(domainName: string) {
  // Browsers can't handle `/./` in the URL
  if (domainName === '.') {
    return '[.]';
  }
  return encodeURIComponent(domainName.endsWith('.') ? domainName.slice(0, -1) : domainName);
}

export function urlPathToDomainName(urlPath: string) {
  // Browsers can't handle `/./` in the URL
  if (urlPath === '[.]') {
    return '.';
  }
  return decodeURIComponent(urlPath).toLowerCase();
}

export function domainNameToDisplayName(domainName: string) {
  if (domainName === '.') {
    return '.';
  }
  return (domainName.endsWith('.') ? domainName.slice(0, -1) : domainName);
}

export function isTheSameDomain(left: string, right: string) {
  return withoutTrailingDot(left) === withoutTrailingDot(right);
}
