/* eslint-disable no-underscore-dangle, @typescript-eslint/ban-ts-comment */
// @ts-ignore
export function askConsent() {
  // @ts-ignore
  if (window.__cmp) {
    // @ts-ignore
    window.__cmp('showModal');
  }
}

export const GA = '1';
export const CRISP = '2';
type CmpService = typeof GA | typeof CRISP;

export function executeIfConsentIsGiven(service: CmpService, consentGiven: () => void, consentNotGiven: () => void) {
  // @ts-ignore
  if (typeof window.__tcfapi === 'function') {
    // @ts-ignore
    __tcfapi('hasCustomConsent', 2, (res: boolean) => {
      if (res) {
        consentGiven();
      } else {
        consentNotGiven();
      }
    }, service);
  }
}

export function executeWhenConsentIsGiven(service: CmpService, cb: () => void) {
  // @ts-ignore
  if (typeof window.__tcfapi === 'function') {
    executeIfConsentIsGiven(service, cb, () => {
      setTimeout(() => {
        executeWhenConsentIsGiven(service, cb);
      }, 1000);
    });
  } else {
    setTimeout(() => {
      executeWhenConsentIsGiven(service, cb);
    }, 1000);
  }
}

export function insertConsentLinks() {
  // @ts-ignore
  if (window.__cmp) {
    // @ts-ignore
    window.__cmp('addConsentLink');
  }
}
