import { DnsServer, RR } from './Api';

const regionalQueryTargets = ['SouthAfrica', 'TheNetherlands', 'Australia', 'India', 'Canada', 'USA', 'Brazil', 'Ukraine', 'Russia'];
const globalQueryTargets = ['CloudFlare', 'Google', 'OpenDns', 'Quad9', 'CleanBrowsing', 'AdGuard', 'NextDNS', 'ControlDUnfiltered', 'UltimateHostsBlacklist'];
export type RegionalQueryTarget = 'SouthAfrica' | 'TheNetherlands' | 'Australia' | 'India' | 'Canada' | 'USA' | 'Brazil' | 'Ukraine' | 'Russia';
export type GlobalQueryTarget = 'CloudFlare' | 'Google' | 'OpenDns' | 'Quad9' | 'CleanBrowsing' | 'AdGuard' | 'NextDNS' | 'ControlDUnfiltered' | 'UltimateHostsBlacklist';
export type QueryTarget = 'Authoritative' | RegionalQueryTarget | GlobalQueryTarget;

export function isRegionalQueryTarget(qt: QueryTarget): qt is RegionalQueryTarget {
  return regionalQueryTargets.includes(qt);
}

export function isGlobalQueryTarget(qt: QueryTarget): qt is GlobalQueryTarget {
  return globalQueryTargets.includes(qt);
}

export type QueryLocation = 'Amsterdam' | 'SanFransisco';

export type DnsPropagationAnswer = {
  queryLocation: QueryLocation;
  queryTarget: QueryTarget;
  server: DnsServer;
  answer: Array<RR>;
}

export type DnsPropagationError = {
  Left: {
    queryTarget: QueryTarget;
    server: string;
  };
}

export type DnsPropagationSuccess = {
  Right: DnsPropagationAnswer;
}

export type DnsPropagationResult = DnsPropagationSuccess | DnsPropagationError;
