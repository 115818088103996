// This file is CommonJS, because `vue.config.js` can't import TypeScript.
// Use `recordTypes.ts` anywhere else.
// TODO: Migrate to TypeScript when NsLookup.io has server-side rendering
exports.recordTypes = [
  {
    upper: 'A',
    lower: 'a',
    cli: 'a',
    description: 'IPv4 address',
    plural: 'IPv4 addresses',
    windowsSupport: true,
    learningPage: '/learning/dns-record-types/a/',
    exampleDomains: ['google.com', 'youtube.com'],
  },
  {
    upper: 'AAAA',
    lower: 'aaaa',
    cli: 'aaaa',
    description: 'IPv6 address',
    plural: 'IPv6 addresses',
    windowsSupport: true,
    learningPage: '/learning/dns-record-types/aaaa/',
    exampleDomains: ['facebook.com', 'netflix.com'],
  },
  {
    upper: 'AFSDB',
    lower: 'afsdb',
    cli: 'afsdb',
    description: 'AFS database location',
    plural: 'AFS database locations',
    windowsSupport: true,
    learningPage: undefined,
    exampleDomains: ['microsoft.com', 'instagram.com'],
  },
  {
    upper: 'APL',
    lower: 'apl',
    cli: 'apl',
    description: 'Address prefix list',
    plural: 'address prefix lists',
    windowsSupport: false,
    learningPage: undefined,
    exampleDomains: ['twitter.com', 'baidu.com'],
  },
  {
    upper: 'AXFR',
    lower: 'axfr',
    cli: 'axfr',
    description: 'Authoritative zone transfer',
    plural: 'authoritative zone transfer records',
    windowsSupport: false,
    learningPage: undefined,
    exampleDomains: ['linkedin.com', 'apple.com'],
  },
  {
    upper: 'CAA',
    lower: 'caa',
    cli: 'caa',
    description: 'Certification authority authorization',
    plural: 'certification authority authorizations',
    windowsSupport: false,
    learningPage: undefined,
    exampleDomains: ['yahoo.com', 'bilibili.com'],
  },
  {
    upper: 'CDNSKEY',
    lower: 'cdnskey',
    cli: 'cdnskey',
    description: 'Child copy of a DNSKEY',
    plural: 'child copies of a DNSKEY records',
    windowsSupport: false,
    learningPage: undefined,
    exampleDomains: ['qq.com', 'amazon.com'],
  },
  {
    upper: 'CDS',
    lower: 'cds',
    cli: 'cds',
    description: 'Child copy of DS',
    plural: 'Child copies of DS records',
    windowsSupport: false,
    learningPage: undefined,
    exampleDomains: ['live.com', 'wordpress.org'],
  },
  {
    upper: 'CERT',
    lower: 'cert',
    cli: 'cert',
    description: 'Cryptographic certificate',
    plural: 'cryptographic certificates',
    windowsSupport: false,
    learningPage: undefined,
    exampleDomains: ['netflix.net', 'bing.com'],
  },
  {
    upper: 'CNAME',
    lower: 'cname',
    cli: 'cname',
    description: 'Canonical name',
    plural: 'canonical names',
    windowsSupport: true,
    learningPage: '/learning/dns-record-types/cname/',
    exampleDomains: ['www.github.com', 'www.reddit.com'],
  },
  {
    upper: 'CSYNC',
    lower: 'csync',
    cli: 'csync',
    description: 'Child-to-parent synchronization',
    plural: 'child-to-parent synchronization records',
    windowsSupport: false,
    learningPage: undefined,
    exampleDomains: ['pinterest.com', 'whatsapp.com'],
  },
  {
    upper: 'DHCID',
    lower: 'dhcid',
    cli: 'dhcid',
    description: 'DHCP identifier',
    plural: 'DHCP identifiers',
    windowsSupport: false,
    learningPage: undefined,
    exampleDomains: ['zhihu.com', 'vimeo.com'],
  },
  {
    upper: 'DLV',
    lower: 'dlv',
    cli: 'dlv',
    description: 'DNSSEC lookaside validation',
    plural: 'DNSSEC lookaside validations',
    windowsSupport: false,
    learningPage: undefined,
    exampleDomains: ['office.com', 'microsoftonline.com'],
  },
  {
    upper: 'DNAME',
    lower: 'dname',
    cli: 'dname',
    description: 'Delegation name',
    plural: 'delegation names',
    windowsSupport: true,
    learningPage: undefined,
    exampleDomains: ['adobe.com', 'amazonaws.com'],
  },
  {
    upper: 'DNSKEY',
    lower: 'dnskey',
    cli: 'dnskey',
    description: 'Cryptographic key for DNSSEC',
    plural: 'cryptographic keys for DNSSEC',
    windowsSupport: false,
    learningPage: undefined,
    exampleDomains: ['wordpress.com', 'zoom.us'],
  },
  {
    upper: 'DS',
    lower: 'ds',
    cli: 'ds',
    description: 'Delegation signer',
    plural: 'delegation signers',
    windowsSupport: false,
    learningPage: undefined,
    exampleDomains: ['taobao.com', 'weibo.com'],
  },
  {
    upper: 'EUI48',
    lower: 'eui48',
    cli: 'eui48',
    description: 'MAC address (EUI-48)',
    plural: 'MAC addresses (EUI-48)',
    windowsSupport: false,
    learningPage: undefined,
    exampleDomains: ['vk.com', 'csdn.net'],
  },
  {
    upper: 'EUI64',
    lower: 'eui64',
    cli: 'eui64',
    description: 'Mac address (EUI-64)',
    plural: 'Mac addresses (EUI-64)',
    windowsSupport: false,
    learningPage: undefined,
    exampleDomains: ['blogspot.com', 'sina.com.cn'],
  },
  {
    upper: 'HINFO',
    lower: 'hinfo',
    cli: 'hinfo',
    description: 'Host information',
    plural: 'host information',
    windowsSupport: true,
    learningPage: undefined,
    exampleDomains: ['mozilla.org', 'msn.com'],
  },
  {
    upper: 'HIP',
    lower: 'hip',
    cli: 'hip',
    description: 'Host identification protocol',
    plural: 'host identification protocols',
    windowsSupport: false,
    learningPage: undefined,
    exampleDomains: ['tumblr.com', 'yandex.ru'],
  },
  {
    upper: 'HTTPS',
    lower: 'https',
    cli: 'https',
    description: 'HTTPS binding',
    plural: 'HTTPS bindings',
    windowsSupport: false,
    learningPage: undefined,
    exampleDomains: ['paypal.com', 'macromedia.com'],
  },
  {
    upper: 'IPSECKEY',
    lower: 'ipseckey',
    cli: 'ipseckey',
    description: 'Cryptographic key for IPsec',
    plural: 'cryptographic keys for IPsec',
    windowsSupport: false,
    learningPage: undefined,
    exampleDomains: ['163.com', 'nytimes.com'],
  },
  {
    upper: 'IXFR',
    lower: 'ixfr',
    cli: 'ixfr',
    description: 'Incremental zone transfer',
    plural: 'incremental zone transfers',
    windowsSupport: true,
    learningPage: undefined,
    exampleDomains: ['sohu.com', 'tiktok.com'],
  },
  {
    upper: 'KEY',
    lower: 'key',
    cli: 'key',
    description: 'Cryptographic key for DNSSEC (obsoleted by DNSKEY)',
    plural: 'cryptographic keys for DNSSEC',
    windowsSupport: true,
    learningPage: undefined,
    exampleDomains: ['jd.com', 'nih.gov'],
  },
  {
    upper: 'KX',
    lower: 'kx',
    cli: 'kx',
    description: 'Key exchange',
    plural: 'key exchanges',
    windowsSupport: false,
    learningPage: undefined,
    exampleDomains: ['tmall.com', 'pornhub.com'],
  },
  {
    upper: 'LOC',
    lower: 'loc',
    cli: 'loc',
    description: 'Geographical location',
    plural: 'geographical locations',
    windowsSupport: false,
    learningPage: undefined,
    exampleDomains: ['xvideos.com', 'skype.com'],
  },
  {
    upper: 'MX',
    lower: 'mx',
    cli: 'mx',
    description: 'Mail exchange',
    plural: 'mail servers',
    windowsSupport: true,
    learningPage: '/learning/dns-record-types/mx/',
    exampleDomains: ['spotify.com', 'europa.eu'],
  },
  {
    upper: 'NAPTR',
    lower: 'naptr',
    cli: 'naptr',
    description: 'Naming authority pointer',
    plural: 'naming authority pointers',
    windowsSupport: false,
    learningPage: undefined,
    exampleDomains: ['flickr.com', 'consortiumeducation.com'],
  },
  {
    upper: 'NS',
    lower: 'ns',
    cli: 'ns',
    description: 'Name server',
    plural: 'name servers',
    windowsSupport: true,
    learningPage: '/learning/dns-record-types/ns/',
    exampleDomains: ['stackoverflow.com', 'imdb.com'],
  },
  {
    upper: 'NSEC3',
    lower: 'nsec3',
    cli: 'nsec3',
    description: 'Next secure (version 3)',
    plural: 'Next secure (version 3) records',
    windowsSupport: false,
    learningPage: undefined,
    exampleDomains: ['medium.com', 'office365.com'],
  },
  {
    upper: 'NSEC3PARAM',
    lower: 'nsec3param',
    cli: 'nsec3param',
    description: 'Parameter for NSEC3',
    plural: 'parameters for NSEC3',
    windowsSupport: false,
    learningPage: undefined,
    exampleDomains: ['canva.com', 'gravatar.com'],
  },
  {
    upper: 'NSEC',
    lower: 'nsec',
    cli: 'nsec',
    description: 'Next secure (obsoleted by NSEC3)',
    plural: 'next secure records',
    windowsSupport: false,
    learningPage: undefined,
    exampleDomains: ['cnn.com', 'twitch.tv'],
  },
  {
    upper: 'NXT',
    lower: 'nxt',
    cli: 'nxt',
    description: 'DNSSEC key (obsoleted by NSEC)',
    plural: 'DNSSEC keys',
    windowsSupport: false,
    learningPage: undefined,
    exampleDomains: ['yahoo.co.jp', 'dropbox.com'],
  },
  {
    upper: 'OPENPGPKEY',
    lower: 'openpgpkey',
    cli: 'openpgpkey',
    description: 'Public key for OpenPGP',
    plural: 'OpenPGP public keys',
    windowsSupport: false,
    learningPage: undefined,
    exampleDomains: ['soundcloud.com', 'aliexpress.com'],
  },
  {
    upper: 'OPT',
    lower: 'opt',
    cli: 'opt',
    description: 'EDNS option',
    plural: 'EDNS options',
    windowsSupport: false,
    learningPage: undefined,
    exampleDomains: ['ebay.com', 'fandom.com'],
  },
  {
    upper: 'PTR',
    lower: 'ptr',
    cli: 'ptr',
    description: 'Canonical name pointer',
    plural: 'canonical name pointers',
    windowsSupport: true,
    learningPage: '/learning/dns-record-types/ptr/',
    exampleDomains: ['1.1.1.1', '199.252.157.234'],
  },
  {
    upper: 'RP',
    lower: 'rp',
    cli: 'rp',
    description: 'Responsible person',
    plural: 'responsible people',
    windowsSupport: true,
    learningPage: undefined,
    exampleDomains: ['naver.com', 'apache.org'],
  },
  {
    upper: 'RRSIG',
    lower: 'rrsig',
    cli: 'rrsig',
    description: 'Resource record signature for DNSSEC',
    plural: 'DNSSEC resource record signatures',
    windowsSupport: false,
    learningPage: undefined,
    exampleDomains: ['cloudflare.com', 'archive.org'],
  },
  {
    upper: 'SIG',
    lower: 'sig',
    cli: 'sig',
    description: 'Resource record signature for DNSSEC (obsoleted by RRSIG)',
    plural: 'DNSSEC resource record signatures',
    windowsSupport: true,
    learningPage: undefined,
    exampleDomains: ['mail.ru', 'theguardian.com'],
  },
  {
    upper: 'SMIMEA',
    lower: 'smimea',
    cli: 'smimea',
    description: 'S/MIME association',
    plural: 'S/MIME associations',
    windowsSupport: false,
    learningPage: undefined,
    exampleDomains: ['forbes.com', 'bbc.co.uk'],
  },
  {
    upper: 'SOA',
    lower: 'soa',
    cli: 'soa',
    description: 'Start of authority',
    plural: 'start of authority records',
    windowsSupport: true,
    learningPage: '/learning/dns-record-types/soa/',
    exampleDomains: ['douban.com', 'w3.org'],
  },
  {
    upper: 'SPF',
    lower: 'spf',
    cli: 'txt',
    description: 'Sender Policy Framework',
    plural: 'SPF records',
    windowsSupport: true,
    learningPage: undefined,
    exampleDomains: ['gmail.com', 'live.com'],
  },
  {
    upper: 'SSHFP',
    lower: 'sshfp',
    cli: 'sshfp',
    description: 'Public key fingerprint for SSH',
    plural: 'SSH public key fingerprints',
    windowsSupport: false,
    learningPage: undefined,
    exampleDomains: ['quora.com', '1688.com'],
  },
  {
    upper: 'SVCB',
    lower: 'svcb',
    cli: 'svcb',
    description: 'Service binding',
    plural: 'service bindings',
    windowsSupport: false,
    learningPage: undefined,
    exampleDomains: ['aparat.com', 'github.io'],
  },
  {
    upper: 'SRV',
    lower: 'srv',
    cli: 'srv',
    description: 'Service locator',
    plural: 'service locators',
    windowsSupport: true,
    learningPage: '/learning/dns-record-types/srv/',
    exampleDomains: ['akamaiedge.net', 'sourceforge.net'],
  },
  {
    upper: 'TA',
    lower: 'ta',
    cli: 'ta',
    description: 'Trust authority for DNSSEC',
    plural: 'DNSSEC trust authorities',
    windowsSupport: false,
    learningPage: undefined,
    exampleDomains: ['digicert.com', 'etsy.com'],
  },
  {
    upper: 'TKEY',
    lower: 'tkey',
    cli: 'tkey',
    description: 'Transaction key',
    plural: 'transaction keys',
    windowsSupport: false,
    learningPage: undefined,
    exampleDomains: ['sciencedirect.com', 'google.co.in'],
  },
  {
    upper: 'TLSA',
    lower: 'tlsa',
    cli: 'tlsa',
    description: 'Certificate association for TLS',
    plural: 'TLS certificate associations',
    windowsSupport: false,
    learningPage: undefined,
    exampleDomains: ['miit.gov.cn', 'creativecommons.org'],
  },
  {
    upper: 'TSIG',
    lower: 'tsig',
    cli: 'tsig',
    description: 'Transaction signature',
    plural: 'transaction signature',
    windowsSupport: false,
    learningPage: undefined,
    exampleDomains: ['outlook.com', 'duckduckgo.com'],
  },
  {
    upper: 'TXT',
    lower: 'txt',
    cli: 'txt',
    description: 'Human-readable text',
    plural: 'text records',
    windowsSupport: true,
    learningPage: '/learning/dns-record-types/txt/',
    exampleDomains: ['researchgate.net', 'cnblogs.com'],
  },
  {
    upper: 'URI',
    lower: 'uri',
    cli: 'uri',
    description: 'Uniform resource identifier',
    plural: 'uniform resource identifiers',
    windowsSupport: false,
    learningPage: undefined,
    exampleDomains: ['weebly.com', 'indeed.com'],
  },
  {
    upper: 'ZONEMD',
    lower: 'zonemd',
    cli: 'zonemd',
    description: 'Message digest for DNS zones',
    plural: 'DNS zone message digests',
    windowsSupport: false,
    learningPage: undefined,
    exampleDomains: ['discord.com', 'reuters.com'],
  },
];
