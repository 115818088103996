import {
  MediumRectangle, LargeRectangle, AdSize, Billboard, LargeLeaderboard, Leaderboard, MobileBanner,
} from './adSizes';

export type AdUnit = {
  id: string;
  unitType: 'nitropay' | 'dnsfordevelopers' | 'hidden';
  utm: string;
  default: Array<AdSize>;
  sm?: Array<AdSize>;
  md?: Array<AdSize>;
  lg?: Array<AdSize>;
  xl?: Array<AdSize>;
  '2xl'?: Array<AdSize>;
};

export const SearchPageAdUnit: AdUnit = {
  id: 'search-page',
  unitType: 'nitropay',
  utm: 'lookup',
  default: [MediumRectangle],
  sm: [MediumRectangle, LargeRectangle],
};

export const DnsRecordsSidebar: AdUnit = {
  id: 'dns-records-sidebar',
  unitType: 'nitropay',
  utm: 'DRtop',
  default: [MediumRectangle],
};

export const DnsRecordsTxt: AdUnit = {
  id: 'dns-records-txt',
  unitType: 'nitropay',
  utm: 'DRmid',
  default: [MediumRectangle],
  lg: [MediumRectangle, LargeLeaderboard],
};

export const DnsRecordsOther: AdUnit = {
  id: 'dns-records-other',
  unitType: 'nitropay',
  utm: 'DRbot',
  default: [MediumRectangle],
  sm: [MediumRectangle, LargeRectangle],
  lg: [MediumRectangle, LargeRectangle, Leaderboard, LargeLeaderboard, Billboard],
};

export const PropagationTop: AdUnit = {
  id: 'propagation-top',
  unitType: 'nitropay',
  utm: 'DNStop',
  default: [MediumRectangle],
  sm: [MediumRectangle, LargeRectangle],
};

export const PropagationMid: AdUnit = {
  id: 'propagation-mid',
  unitType: 'nitropay',
  utm: 'DNSmid',
  default: [MediumRectangle],
  sm: [MediumRectangle, LargeRectangle],
  lg: [MediumRectangle, LargeRectangle, Leaderboard, LargeLeaderboard],
};

export const PropagationBottom: AdUnit = {
  id: 'propagation-bottom',
  unitType: 'nitropay',
  utm: 'DNSbot',
  default: [MediumRectangle],
  sm: [MediumRectangle, LargeRectangle],
  lg: [MediumRectangle, LargeRectangle, Leaderboard, LargeLeaderboard, Billboard],
};

export const RecordTypeLookup: AdUnit = {
  id: 'record-type-lookup',
  unitType: 'nitropay',
  utm: 'lookup',
  default: [MediumRectangle],
  sm: [MediumRectangle, LargeRectangle],
};

export const RecordTypeResults: AdUnit = {
  id: 'record-type-results',
  unitType: 'nitropay',
  utm: 'RTRP',
  default: [MediumRectangle],
  sm: [MediumRectangle, LargeRectangle],
  lg: [MediumRectangle, LargeRectangle, Leaderboard, LargeLeaderboard, Billboard],
};

export const ReverseIpLookup: AdUnit = {
  id: 'reverse-ip-lookup',
  unitType: 'nitropay',
  utm: 'lookup',
  default: [MediumRectangle],
  sm: [MediumRectangle, LargeRectangle],
};

export const ReverseIpResults: AdUnit = {
  id: 'reverse-ip-results',
  unitType: 'nitropay',
  utm: 'lookup',
  default: [MediumRectangle],
  sm: [MediumRectangle, LargeRectangle],
  lg: [MediumRectangle, LargeRectangle, Leaderboard, LargeLeaderboard, Billboard],
};

export const WebsiteToIpLookup: AdUnit = {
  id: 'website-to-ip-lookup',
  unitType: 'nitropay',
  utm: 'lookup',
  default: [MediumRectangle],
  sm: [MediumRectangle, LargeRectangle],
};

export const WebsiteToIpResultsTop: AdUnit = {
  id: 'website-to-ip-results-top',
  unitType: 'nitropay',
  utm: 'WebtoIPtop',
  default: [MobileBanner],
  sm: [Leaderboard],
  lg: [Leaderboard, LargeLeaderboard],
};

export const WebsiteToIpResultsSide: AdUnit = {
  id: 'website-to-ip-results-side',
  unitType: 'nitropay',
  utm: 'WebtoIPmid',
  default: [],
  xl: [MediumRectangle, LargeRectangle],
};

export const WebsiteToIpResultsBottom: AdUnit = {
  id: 'website-to-ip-results-bottom',
  unitType: 'nitropay',
  utm: 'WebtoIPbot',
  default: [MediumRectangle],
  sm: [MediumRectangle, LargeRectangle],
  lg: [MediumRectangle, LargeRectangle, Leaderboard, LargeLeaderboard, Billboard],
};
