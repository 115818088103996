import {
  DnsPropagationAnswer, GlobalQueryTarget, isGlobalQueryTarget, RegionalQueryTarget,
} from '@/model/PropagationEndpoint';

export type DnsOrganization = 'CloudFlare' | 'Google' | 'OpenDns' | 'Quad9' | 'CleanBrowsing' | 'AdGuard' | 'NextDNS' | 'ControlDUnfiltered' | 'UltimateHostsBlacklist' |
  'AWS' | 'Godaddy' | 'Akamai' | 'Enom' | 'DnsMadeEasy' | 'NS1' | 'Wordpress' | 'Dreamhost' | 'Namecheap' | 'Gandi' | 'Linode' |
  'DNSimple' | 'Siteground' | 'DigitalOcean' | 'DynDNS' | 'Azure' | 'OVH' | 'IONOS' | 'Siteground' | 'Bluehost' | 'Wix' | 'Hover' |
  'Verisign' | 'ICB' | 'Afilias' | 'UniversityOfSouthernCalifornia' | 'Cogent' | 'UniversityOfMaryland' | 'NASA' | 'ISC' |
  'UsDepartmentOfDefense' | 'UsArmy' | 'Netnod' | 'RIPE' | 'ICANN' | 'WideProject' | 'NetworkSolutions' | 'Register' | 'Squarespace' |
  'HostGator' | 'ClouDNS' | 'NameBright' | 'ArubaIt' | 'NameCom' | 'InMotion' | 'A2Hosting' | 'OneCom' | 'Ezoic' | 'Unknown';
export type Country = 'SouthAfrica' | 'TheNetherlands' | 'Australia' | 'India' | 'Canada' | 'USA' | 'Brazil' | 'Ukraine' | 'Russia';
export type HostingEntity = DnsOrganization | Country;

const knownDnsServerSuffixes: Array<{ suffix: string, organization: DnsOrganization}> = [
  { suffix: '.cloudflare.com.', organization: 'CloudFlare' },
  { suffix: '.domaincontrol.com.', organization: 'Godaddy' },
  { suffix: '.akam.net.', organization: 'Akamai' },
  { suffix: '.googledomains.com.', organization: 'Google' },
  { suffix: '.name-services.com.', organization: 'Enom' },
  { suffix: '.dnsmadeeasy.com.', organization: 'DnsMadeEasy' },
  { suffix: '.nsone.net.', organization: 'NS1' },
  { suffix: '.wordpress.com.', organization: 'Wordpress' },
  { suffix: '.wordpress.org.', organization: 'Wordpress' },
  { suffix: '.dreamhost.com.', organization: 'Dreamhost' },
  { suffix: '.registrar-servers.com.', organization: 'Namecheap' },
  { suffix: '.namecheaphosting.com.', organization: 'Namecheap' },
  { suffix: '.gandi.net.', organization: 'Gandi' },
  { suffix: '.linode.com.', organization: 'Linode' },
  { suffix: '.dnsimple.com.', organization: 'DNSimple' },
  { suffix: '.siteground.com.', organization: 'Siteground' },
  { suffix: '.siteground.net.', organization: 'Siteground' },
  { suffix: '.digitalocean.com.', organization: 'DigitalOcean' },
  { suffix: '.dynect.net.', organization: 'DynDNS' },
  { suffix: '.dyn.com.', organization: 'DynDNS' },
  { suffix: '.azure-dns.com.', organization: 'Azure' },
  { suffix: '.azure-dns.org.', organization: 'Azure' },
  { suffix: '.azure-dns.net.', organization: 'Azure' },
  { suffix: '.azure-dns.info.', organization: 'Azure' },
  { suffix: '.ovh.net.', organization: 'OVH' },
  { suffix: '.ui-dns.de.', organization: 'IONOS' },
  { suffix: '.ui-dns.org.', organization: 'IONOS' },
  { suffix: '.ui-dns.biz.', organization: 'IONOS' },
  { suffix: '.ui-dns.com.', organization: 'IONOS' },
  { suffix: '.bluehost.com.', organization: 'Bluehost' },
  { suffix: '.wixdns.net.', organization: 'Wix' },
  { suffix: '.hover.com.', organization: 'Hover' },
  { suffix: '.worldnic.com.', organization: 'NetworkSolutions' },
  { suffix: '.register.com.', organization: 'Register' },
  { suffix: '.squarespacedns.com.', organization: 'Squarespace' },
  { suffix: '.hostgator.com.', organization: 'HostGator' },
  { suffix: '.cloudns.net.', organization: 'ClouDNS' },
  { suffix: '.namebrightdns.com.', organization: 'NameBright' },
  { suffix: '.technorail.com.', organization: 'ArubaIt' },
  { suffix: '.arubadns.net.', organization: 'ArubaIt' },
  { suffix: '.arubadns.cz.', organization: 'ArubaIt' },
  { suffix: '.name.com.', organization: 'NameCom' },
  { suffix: '.inmotionhosting.com.', organization: 'InMotion' },
  { suffix: '.a2hosting.com.', organization: 'A2Hosting' },
  { suffix: '.one.com.', organization: 'OneCom' },
  { suffix: '.ezoicns.com.', organization: 'Ezoic' },
  { suffix: '.gtld-servers.net.', organization: 'Verisign' },
  { suffix: '.nic.io.', organization: 'ICB' },
  { suffix: '.nic.ac.', organization: 'ICB' },
  { suffix: '.nic.sh.', organization: 'ICB' },
  { suffix: '.afilias-nst.info.', organization: 'Afilias' },
  { suffix: '.afilias-nst.org.', organization: 'Afilias' },
  { suffix: 'a.root-servers.net.', organization: 'Verisign' },
  { suffix: 'b.root-servers.net.', organization: 'UniversityOfSouthernCalifornia' },
  { suffix: 'c.root-servers.net.', organization: 'Cogent' },
  { suffix: 'd.root-servers.net.', organization: 'UniversityOfMaryland' },
  { suffix: 'e.root-servers.net.', organization: 'NASA' },
  { suffix: 'f.root-servers.net.', organization: 'ISC' },
  { suffix: 'g.root-servers.net.', organization: 'UsDepartmentOfDefense' },
  { suffix: 'h.root-servers.net.', organization: 'UsArmy' },
  { suffix: 'i.root-servers.net.', organization: 'Netnod' },
  { suffix: 'j.root-servers.net.', organization: 'Verisign' },
  { suffix: 'k.root-servers.net.', organization: 'RIPE' },
  { suffix: 'l.root-servers.net.', organization: 'ICANN' },
  { suffix: 'm.root-servers.net.', organization: 'WideProject' },
];

export function displayHostingEntity(entity: HostingEntity): string {
  switch (entity) {
    case 'CloudFlare':
      return 'Cloudflare';
    case 'Google':
      return 'Google DNS';
    case 'OpenDns':
      return 'Open DNS';
    case 'Quad9':
      return 'Quad 9';
    case 'AWS':
      return 'Amazon Web Services';
    case 'ControlDUnfiltered':
      return 'Control D';
    case 'UltimateHostsBlacklist':
      return 'Ultimate Hosts Blacklist';
    case 'Godaddy':
      return 'GoDaddy';
    case 'DnsMadeEasy':
      return 'DNS Made Easy';
    case 'DynDNS':
      return 'Oracle Cloud (DynDNS)';
    case 'Azure':
      return 'Microsoft Azure';
    case 'NetworkSolutions':
      return 'Network Solutions';
    case 'Register':
      return 'Register.com';
    case 'ArubaIt':
      return 'Aruba.it';
    case 'NameCom':
      return 'Name.com';
    case 'InMotion':
      return 'InMotion Hosting';
    case 'A2Hosting':
      return 'A2 Hosting';
    case 'OneCom':
      return 'One.com';
    case 'SouthAfrica':
      return 'South Africa';
    case 'TheNetherlands':
      return 'The Netherlands';
    case 'USA':
      return 'United States of Amarica';
    case 'UniversityOfSouthernCalifornia':
      return 'University of Southern California';
    case 'Cogent':
      return 'Cogent Communications';
    case 'UniversityOfMaryland':
      return 'University of Maryland';
    case 'UsDepartmentOfDefense':
      return 'US Department of Defense';
    case 'UsArmy':
      return 'US Army';
    case 'WideProject':
      return 'Wide Project';
    default:
      return entity;
  }
}

function toDnsOrganization(dnsServerDomainName: string | null): DnsOrganization {
  if (dnsServerDomainName) {
    const org = knownDnsServerSuffixes.find((suffix) => dnsServerDomainName.endsWith(suffix.suffix))?.organization;
    if (org) {
      return org;
    }
    if (dnsServerDomainName.includes('.awsdns-')) {
      return 'AWS';
    }
  }
  return 'Unknown';
}

export function organizationIconSrc(org: DnsOrganization): string {
  if (org === 'Unknown') {
    return 'img/flags/1x1/xx.svg';
  }
  return `/img/logos/${org.toLowerCase()}.png`;
}

export function flagIconSrc(country: Country, square = false): string {
  switch (country) {
    case 'SouthAfrica':
      return `img/flags/${square ? '1x1' : '4x3'}/za.svg`;
    case 'TheNetherlands':
      return `img/flags/${square ? '1x1' : '4x3'}/nl.svg`;
    case 'Australia':
      return `img/flags/${square ? '1x1' : '4x3'}/au.svg`;
    case 'India':
      return `img/flags/${square ? '1x1' : '4x3'}/in.svg`;
    case 'Canada':
      return `img/flags/${square ? '1x1' : '4x3'}/ca.svg`;
    case 'USA':
      return `img/flags/${square ? '1x1' : '4x3'}/us.svg`;
    case 'Brazil':
      return `img/flags/${square ? '1x1' : '4x3'}/br.svg`;
    case 'Ukraine':
      return `img/flags/${square ? '1x1' : '4x3'}/ua.svg`;
    case 'Russia':
      return `img/flags/${square ? '1x1' : '4x3'}/ru.svg`;
    default:
      return `img/flags/${square ? '1x1' : '4x3'}/xx.svg`;
  }
}

function globalQueryTargetToOrganization(qt: GlobalQueryTarget): DnsOrganization {
  return qt;
}

function regionalQueryTargetToCountry(qt: RegionalQueryTarget): Country {
  return qt;
}

export function iconSrc(answer: DnsPropagationAnswer): string {
  if (answer.queryTarget === 'Authoritative') {
    return organizationIconSrc(toDnsOrganization(answer.server.domain));
  }
  if (isGlobalQueryTarget(answer.queryTarget)) {
    return organizationIconSrc(globalQueryTargetToOrganization(answer.queryTarget));
  }
  return flagIconSrc(answer.queryTarget, true);
}

export function iconAlt(answer: DnsPropagationAnswer): string {
  if (answer.queryTarget === 'Authoritative') {
    const org = displayHostingEntity(toDnsOrganization(answer.server.domain));
    if (org === 'Unknown') {
      return `Authoritative server ${answer.server.domain}` || '';
    }
    return `Authoritative server ${answer.server.domain} (by ${org})`;
  }
  if (isGlobalQueryTarget(answer.queryTarget)) {
    return `${answer.server.ip} (by ${displayHostingEntity(globalQueryTargetToOrganization(answer.queryTarget))})`;
  }
  return displayHostingEntity(regionalQueryTargetToCountry(answer.queryTarget));
}
