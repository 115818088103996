import { createStore } from 'vuex';

interface State {
  showRawOutput: boolean;
  showAds: boolean;
  showBanner: boolean;
  adsBlocked: boolean;
}

export default createStore({
  state: {
    showRawOutput: false,
    showAds: true,
    showBanner: true,
    adsBlocked: false,
  },
  mutations: {
    toggleRawOutput(state: State) {
      state.showRawOutput = !state.showRawOutput;
    },
    toggleAds(state: State) {
      state.showAds = !state.showAds;
    },
    toggleBanner(state: State) {
      state.showBanner = !state.showBanner;
    },
    setAdsBlocked(state: State) {
      state.adsBlocked = true;
    },
    setAdsNotBlocked(state: State) {
      state.adsBlocked = false;
    },
  },
  actions: {
  },
  modules: {
  },
});
