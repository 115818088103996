// eslint-disable-next-line @typescript-eslint/ban-types
export default function debounce<T extends Function>(cb: T, wait = 20) {
  let h = 0;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const callable = (...args: any) => {
    clearTimeout(h);
    h = setTimeout(() => cb(...args), wait);
  };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return <T>(<any>callable);
}
