/* eslint-disable no-multi-spaces */

// Square
export const MediumRectangle =   ['300', '250'];
export const LargeRectangle =    ['336', '280'];

// Horizontal
export const Leaderboard =       ['728', '90'];
export const LargeLeaderboard =  ['970', '90'];
export const Billboard =         ['970', '250'];

// Vertical
export const WideSkyscraper =    ['160', '600'];
export const HalfPage =          ['300', '600'];

// Mobile
export const MobileBanner =      ['320', '50'];
export const LargeMobileBanner = ['320', '100'];

export type AdSize = typeof MediumRectangle | typeof Leaderboard | typeof LargeLeaderboard | typeof Billboard |
  typeof LargeRectangle | typeof MobileBanner | typeof LargeMobileBanner | typeof WideSkyscraper | typeof HalfPage;

export type Breakpoint = 'sm' | 'md' | 'lg' | 'xl' | '2xl';

const breakpoints = {
  default: 0,
  sm: 640,
  md: 768,
  lg: 1024,
  xl: 1280,
  '2xl': 1536,
};

export function mediaQuery(min?: Breakpoint, maxExcluding?: Breakpoint) {
  if (min && maxExcluding) {
    return `(min-width: ${breakpoints[min]}px) and (max-width: ${breakpoints[maxExcluding] - 1}px)`;
  }
  if (min) {
    return `(min-width: ${breakpoints[min]}px)`;
  }
  if (maxExcluding) {
    return `(max-width: ${breakpoints[maxExcluding] - 1}px)`;
  }
  return undefined;
}
