<template>
  <Teleport to="head">
    <component :is="'script'" type="application/javascript">
      (function(){
      var fullres = document.createElement('script');
      fullres.async = true;
      fullres.src = 'https://t.fullres.net/nslookup.js?'+(new Date()-new Date()%43200000);
      document.head.appendChild(fullres);
      })();
    </component>
  </Teleport>
</template>
