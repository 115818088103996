<template>
  <a
    href="https://ddos-guard.net/?utm_source=nslookup-io&utm_medium=banner&utm_campaign=nslookup-io_banner-300x250_cybernovember_en"
    rel="sponsored noopener"
    class="'w-[300px] h-[250px] block relative overflow-hidden rounded-md shadow-md border border-black/10">
    <img
      src="/img/ddosguard.png"
      alt="DDoS-Guard - Save up to 50% on DDos protection and cloud solutions"
      class="w-full h-full object-cover object-center">
  </a>
</template>
