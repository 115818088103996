import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import { recordTypeRegex } from '@/common/recordTypes';
import RecordTypeLookup from '@/views/RecordTypeLookup.vue';
import RecordTypeLookupResults from '@/views/RecordTypeLookupResults.vue';
import DnsChecker from '@/views/DnsChecker.vue';
import ReverseIpLookup from '@/views/ReverseIpLookup.vue';
import ReverseIpLookupResults from '@/views/ReverseIpLookupResults.vue';
import WebsiteToIpLookup from '@/views/WebsiteToIpLookup.vue';
import WebsiteToIpLookupResults from '@/views/WebsiteToIpLookupResults.vue';
import Home from '@/views/HomeView.vue';
import DnsRecords from '@/views/DnsRecordsResults.vue';
import DnsPropagation from '@/views/DnsPropagationResults.vue';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/dns-checker/',
    name: 'Dns Checker',
    component: DnsChecker,
  },
  {
    path: '/website-to-ip-lookup/',
    name: 'Website to IP lookup',
    component: WebsiteToIpLookup,
  },
  {
    path: '/reverse-ip-lookup/',
    name: 'Reverse IP lookup',
    component: ReverseIpLookup,
  },
  {
    path: '/ip/:routerIp(.*)/domain-names/',
    props: true,
    name: 'Reverse IP lookup result',
    component: ReverseIpLookupResults,
  },
  {
    path: '/domains/:routerDomain(.*)/webservers/',
    props: true,
    name: 'Webservers',
    component: WebsiteToIpLookupResults,
  },
  {
    path: '/domains/:routerDomain(.*)/dns-records/',
    props: true,
    name: 'Dns records', // DnsRecordsResults.vue depends on this magic string.
    component: DnsRecords,
  },
  {
    path: `/domains/:routerDomain(.*)/dns-propagation/:routerRecordType(${recordTypeRegex})/`,
    props: true,
    name: 'Dns propagation',
    component: DnsPropagation,
  },
  {
    path: `/:routerRecordType(${recordTypeRegex})-lookup/`,
    props: true,
    name: 'Record type lookup',
    component: RecordTypeLookup,
  },
  {
    path: `/domains/:routerDomain(.*)/dns-records/:routerRecordType(${recordTypeRegex})/`,
    props: true,
    name: 'Record type lookup result',
    component: RecordTypeLookupResults,
  },
  {
    path: '/domains/:routerDomain(.*)/email/:routerRecordType(spf)/',
    props: true,
    name: 'SPF lookup result',
    component: RecordTypeLookupResults,
  },
  {
    path: '/install/',
    name: 'Install',
    component: () => import(/* webpackChunkName: "content" */'@/views/InstallView.vue'),
  },
  {
    path: '/install/how-to-view-dns-records-in-chrome/',
    name: 'Install in Chrome',
    component: () => import(/* webpackChunkName: "content" */'@/views/install/InstallChrome.vue'),
  },
  {
    path: '/install/how-to-view-dns-records-in-edge/',
    name: 'Install in Edge',
    component: () => import(/* webpackChunkName: "content" */'@/views/install/InstallEdge.vue'),
  },
  {
    path: '/install/how-to-view-dns-records-in-firefox/',
    name: 'Install in Firefox',
    component: () => import(/* webpackChunkName: "content" */'@/views/install/InstallFirefox.vue'),
  },
  {
    path: '/contact-us/',
    name: 'Contact',
    component: () => import(/* webpackChunkName: "content" */'../views/ContactUs.vue'),
  },
  {
    path: '/about/',
    name: 'About',
    component: () => import(/* webpackChunkName: "content" */'../views/AboutView.vue'),
  },
  {
    path: '/about/jeff-westhead/',
    name: 'Jeff Westhead',
    component: () => import(/* webpackChunkName: "content" */'../views/AboutJeff.vue'),
  },
  {
    path: '/about/ruurtjan-pul/',
    name: 'Ruurtjan Pul',
    component: () => import(/* webpackChunkName: "content" */'../views/AboutRuurtjan.vue'),
  },
  {
    path: '/dns-lookup-api/',
    name: 'DNS lookup API',
    component: () => import(/* webpackChunkName: "content" */'../views/ApiView.vue'),
  },
  {
    path: '/welcome-to-the-course/',
    name: 'Welcome to the course',
    component: () => import(/* webpackChunkName: "content" */'../views/WelcomeToTheCourse.vue'),
  },
  {
    path: '/sponsor/',
    name: 'Sponsor NsLookup.io',
    component: () => import(/* webpackChunkName: "content" */'../views/SponsorView.vue'),
  },
  {
    path: '/privacy-policy/',
    name: 'Privacy policy',
    component: () => import(/* webpackChunkName: "content" */'../views/PrivacyPolicy.vue'),
  },
  {
    path: '/terms-and-conditions/',
    name: 'Terms and conditions',
    component: () => import(/* webpackChunkName: "content" */'../views/TermsAndConditions.vue'),
  },
  {
    path: '/learning/',
    name: 'Learning',
    component: () => import(/* webpackChunkName: "content" */'../views/LearningView.vue'),
  },
  {
    path: '/learning/what-is-dns/',
    name: 'What is the DNS?',
    component: () => import(/* webpackChunkName: "content" */'../views/learning/WhatIsDns.vue'),
  },
  {
    path: '/learning/what-is-a-dns-resolver/',
    name: 'What is a DNS resolver?',
    component: () => import(/* webpackChunkName: "content" */'../views/learning/WhatIsADnsResolver.vue'),
  },
  {
    path: '/learning/dns-record-types/',
    name: 'DNS Record Types',
    component: () => import(/* webpackChunkName: "content" */'../views/learning/DnsRecordTypes.vue'),
  },
  {
    path: '/learning/what-is-a-dns-zone/',
    name: 'What is a DNS zone?',
    component: () => import(/* webpackChunkName: "content" */'../views/learning/WhatIsADnsZone.vue'),
  },
  {
    path: '/learning/what-is-the-dns-root-zone/',
    name: 'What is the DNS root zone?',
    component: () => import(/* webpackChunkName: "content" */'../views/learning/WhatIsTheDnsRootZone.vue'),
  },
  {
    path: '/learning/recursive-vs-authoritative-dns/',
    name: 'Recursive versus authoritative DNS',
    component: () => import(/* webpackChunkName: "content" */'../views/learning/RecursiveVsAuthoritativeDns.vue'),
  },
  {
    path: '/learning/zone-delegation/',
    name: 'DNS Zone Delegation',
    component: () => import(/* webpackChunkName: "content" */'../views/learning/ZoneDelegation.vue'),
  },
  {
    path: '/learning/how-does-domain-name-registration-work/',
    name: 'How does domain name registration work?',
    component: () => import(/* webpackChunkName: "content" */'../views/learning/HowDoesDomainNameRegistrationWork.vue'),
  },
  {
    path: '/learning/10-tips-for-picking-the-perfect-domain-name/',
    name: '10 tips for picking the perfect domain name',
    component: () => import(/* webpackChunkName: "content" */'@/views/learning/domain-names/TipsForPickingADomainName.vue'),
  },
  {
    path: '/learning/security/dns-changer/',
    name: 'What is the DNS Changer Malware?',
    component: () => import(/* webpackChunkName: "content" */'../views/learning/security/DnsChanger.vue'),
  },
  {
    path: '/learning/dns-record-types/soa/',
    name: 'The SOA record',
    component: () => import(/* webpackChunkName: "content" */'../views/learning/DnsRecordTypes/RecordTypeSoa.vue'),
  },
  {
    path: '/learning/dns-record-types/cname/',
    name: 'The CNAME record',
    component: () => import(/* webpackChunkName: "content" */'../views/learning/DnsRecordTypes/RecordTypeCname.vue'),
  },
  {
    path: '/learning/dns-record-types/ns/',
    name: 'The NS record',
    component: () => import(/* webpackChunkName: "content" */'../views/learning/DnsRecordTypes/RecordTypeNs.vue'),
  },
  {
    path: '/learning/dns-record-types/mx/',
    name: 'The MX record',
    component: () => import(/* webpackChunkName: "content" */'../views/learning/DnsRecordTypes/RecordTypeMx.vue'),
  },
  {
    path: '/learning/dns-record-types/txt/',
    name: 'The TXT record',
    component: () => import(/* webpackChunkName: "content" */'../views/learning/DnsRecordTypes/RecordTypeTxt.vue'),
  },
  {
    path: '/learning/dns-record-types/a/',
    name: 'The A record',
    component: () => import(/* webpackChunkName: "content" */'../views/learning/DnsRecordTypes/RecordTypeA.vue'),
  },
  {
    path: '/learning/dns-record-types/aaaa/',
    name: 'The AAAA record',
    component: () => import(/* webpackChunkName: "content" */'../views/learning/DnsRecordTypes/RecordTypeAAAA.vue'),
  },
  {
    path: '/learning/dns-record-types/srv/',
    name: 'The SRV record',
    component: () => import(/* webpackChunkName: "content" */'../views/learning/DnsRecordTypes/RecordTypeSrv.vue'),
  },
  {
    path: '/learning/dns-record-types/ptr/',
    name: 'The PTR record',
    component: () => import(/* webpackChunkName: "content" */'../views/learning/DnsRecordTypes/RecordTypePtr.vue'),
  },
  {
    path: '/learning/spf-a-practical-guide/',
    name: 'Email security SPF',
    component: () => import(/* webpackChunkName: "content" */'../views/learning/EmailSecurity/EmailSecuritySpf.vue'),
  },
  {
    path: '/learning/dkim-a-practical-guide/',
    name: 'Email security DKIM',
    component: () => import(/* webpackChunkName: "content" */'../views/learning/EmailSecurity/EmailSecurityDkim.vue'),
  },
  {
    path: '/learning/dmarc-a-practical-guide/',
    name: 'Email security DMARC',
    component: () => import(/* webpackChunkName: "content" */'../views/learning/EmailSecurity/EmailSecurityDmarc.vue'),
  },
  {
    path: '/learning/how-to-merge-dmarc-records/',
    name: 'How to merge DMARC records',
    component: () => import(/* webpackChunkName: "content" */'../views/learning/EmailSecurity/EmailSecurityHowToMergeDmarcRecords.vue'),
  },
  {
    path: '/learning/mx-vs-spf-vs-dmarc-vs-dkim-vs-bimi/',
    name: 'MX vs SPF vs DMARC vs DKIM vs BIMI',
    component: () => import(/* webpackChunkName: "content" */'../views/learning/MxVsSpfVsDmarcVsDkimVsBimi.vue'),
  },
  {
    path: '/learning/using-different-dmarc-records-for-subdomains/',
    name: 'Using different DMARC records for subdomains',
    component: () => import(/* webpackChunkName: "content" */'../views/learning/EmailSecurity/EmailSecurityUsingDifferentDmarcRecordsForSubdomains.vue'),
  },
  {
    path: '/learning/the-life-of-a-dns-query-in-kubernetes/',
    name: 'The life of a DNS query in Kubernetes',
    component: () => import(/* webpackChunkName: "content" */'../views/learning/TheLifeOfADnsQueryInKubernetes.vue'),
  },
  {
    path: '/learning/how-to-specify-a-dns-server-in-nslookup/',
    name: 'How to specify a DNS server in nslookup',
    component: () => import(/* webpackChunkName: "content" */'../views/learning/command-line/HowToSpecifyADnsServerInNslookup.vue'),
  },
  {
    path: '/learning/dns-propagation-does-not-exist/',
    name: 'DNS propagation does not exist',
    component: () => import(/* webpackChunkName: "content" */'../views/learning/DnsPropagationDoesNotExist.vue'),
  },
  {
    path: '/learning/what-is-a-good-ttl-for-dns/',
    name: 'What is a good TTL for DNS?',
    component: () => import(/* webpackChunkName: "content" */'../views/learning/WhatIsAGoodTtlForDns.vue'),
  },
  {
    path: '/learning/finding-whois-servers-using-srv-records/',
    name: 'Finding WHOIS servers using SRV records',
    component: () => import(/* webpackChunkName: "content" */'../views/learning/FindingWhoisServersUsingSrvRecords.vue'),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to) {
    if (to.hash) {
      const element = document.getElementById(to.hash.substring(1));
      if (element) {
        return {
          el: element,
        };
      }
    }
    return {
      el: '#app',
    };
  },
});

declare function plausible(eventName: string, options?: { u?: string }): void;

router.afterEach((to, from) => {
  if (to.path !== from.path || to.hash === from.hash) {
    const url = window.location.origin + to.path
      .replace(/\/domains\/.*?\//g, '/domains/_ID_/')
      .replace(/\/ip\/.*?\//g, '/ip/_ID_/');
    plausible('pageview', { u: url + window.location.search });
  }
});

export default router;
