// eslint-disable-next-line import/prefer-default-export
export const dnsServers = {
  cloudflare: {
    pretty: 'Cloudflare',
    type: 'popular',
  },
  google: {
    pretty: 'Google',
    type: 'popular',
  },
  controldunfiltered: {
    pretty: 'Control D Unfintered',
    type: 'control d',
  },
  controldmalware: {
    pretty: 'Control D Malware',
    type: 'control d',
  },
  controldadstracking: {
    pretty: 'Control D Ads & Tracking',
    type: 'control d',
  },
  controldsocial: {
    pretty: 'Control D Social',
    type: 'control d',
  },
  controldfamilyfriendly: {
    pretty: 'Control D Family Friendly',
    type: 'control d',
  },
  controlduncesored: {
    pretty: 'Control D Uncensored',
    type: 'control d',
  },
  authoritative: {
    pretty: 'authoritative',
    type: 'authoritative',
  },
  southafrica: {
    pretty: 'South Africa',
    type: 'local',
  },
  australia: {
    pretty: 'Australia',
    type: 'local',
  },
  india: {
    pretty: 'India',
    type: 'local',
  },
  russia: {
    pretty: 'Russia',
    type: 'local',
  },
  thenetherlands: {
    pretty: 'The Netherlands',
    type: 'local',
  },
  ukraine: {
    pretty: 'Ukraine',
    type: 'local',
  },
  canada: {
    pretty: 'Canada',
    type: 'local',
  },
  usa: {
    pretty: 'United States of America',
    type: 'local',
  },
  brazil: {
    pretty: 'Brazil',
    type: 'local',
  },
};
