import { createApp } from 'vue';
import Prism from 'prismjs';
import App from './App.vue';
import router from './router';
import store from './store';

Prism.manual = true;

const root = createApp(App).use(store).use(router);

// As suggested by https://github.com/chrisvfritz/prerender-spa-plugin#js-not-firing-before-prerender
document.addEventListener('DOMContentLoaded', () => {
  root.mount('#app');
});
