export type ApiErrorType = 'Unknown' | 'HasNoReverseDnsRecord' | 'InvalidDomain' | 'DnsQueryFailed' | 'NoDnsServersAvailable'

export function toErrorType(errorMessage: string): ApiErrorType {
  switch (errorMessage) {
    case 'IP has no reverse DNS record':
      return 'HasNoReverseDnsRecord';
    case 'Invalid domain':
      return 'InvalidDomain';
    case 'DNS query failed':
      return 'DnsQueryFailed';
    case 'No DNS servers available':
      return 'NoDnsServersAvailable';
    default:
      return 'Unknown';
  }
}
