type RecordType = {
  upper: string;
  lower: string;
  cli: string;
  description: string;
  plural: string;
  windowsSupport: boolean;
  learningPage: string | undefined;
  exampleDomains: Array<string>;
}

// eslint-disable-next-line @typescript-eslint/no-var-requires,global-require
export const recordTypes = require('./recordTypeData.cjs').recordTypes as Array<RecordType>;

const recordTypeNames = recordTypes.map((type) => type.upper);

export const recordTypeRegex = recordTypes.map((type) => type.lower).join('|');
export type RecordTypesUnion = (typeof recordTypeNames)[number];

export function getRecordType(recordType: RecordTypesUnion): RecordType {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  return recordTypes.find((r) => r.upper === recordType.toUpperCase())!;
}
