import { RR } from '@/model/Api';
import { DnsPropagationAnswer } from '@/model/PropagationEndpoint';

export interface Variant {
  records: Array<RR>;
  answers: Array<DnsPropagationAnswer>;
  isAuthoritative: boolean;
}

function rrEquals(a: RR, b: RR): boolean {
  return a.raw === b.raw && a.name === b.name && a.recordType === b.recordType;
}

function responseEquals(a: Array<RR>, b: Array<RR>): boolean {
  if (a.length !== b.length) {
    return false;
  }

  const remaining = [...b];
  return a.every((element) => {
    const index = remaining.findIndex((rr) => rrEquals(rr, element));
    if (index === -1) {
      return false;
    }
    remaining.splice(index, 1);
    return true;
  });
}

export function addResponse(
  variants: Array<Variant>,
  answer: DnsPropagationAnswer,
  recordType: string,
): Array<Variant> {
  const records = answer.answer.filter((rr) => rr.recordType === recordType);
  const index = variants.findIndex((variant) => responseEquals(variant.records, records));
  if (index === -1) {
    return [...variants, { records, answers: [answer], isAuthoritative: answer.queryTarget === 'Authoritative' }];
  }

  const newVariants = [...variants];
  newVariants[index].answers.push(answer);
  if (answer.queryTarget === 'Authoritative') {
    newVariants[index].isAuthoritative = true;
  }
  return variants;
}

export function getVariantName(index: number): string {
  return String.fromCharCode(65 + index);
}

export function getVariant(variants: Array<Variant>, answer: DnsPropagationAnswer): string {
  const index = variants.findIndex((variant) => variant.answers.includes(answer));
  if (index === -1) {
    return '';
  }
  return getVariantName(index);
}
